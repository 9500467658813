import {Card,Input} from "antd";
import React from "react";
import Login from "./login"
import Private from "./private"
import {KeyContext} from "../KeysContext";
export default (props)=>{
    let globalTokens = React.useContext(KeyContext);


    return <div>
        {globalTokens.state.readKey==""?<Login/>:[
           <Private/>


        ]}




    </div>
}