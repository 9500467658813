import {Card, Input, Drawer, Table, Button,Icon} from "antd";
import React, {useState} from "react";
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {KeyContext} from "../../KeysContext";
import _ from "underscore";
import axios from "axios"
import {apiURL,webURL} from "../../config"
import InQuality from "./inQuality"
import moment from "moment"
const getLogs = gql(`
query getLogs($pass: String, $_id:String!) {
  superadmin(pass: $pass) {
    organisation(_id:$_id){
      
      logs{
        text
        changerId
        erfassungId
        einrichtungId
        userId
        createdAt
        auftragId
        
      }
    }
  }
}


`)



export default (props)=>{
    let globalTokens = React.useContext(KeyContext);

    const [token,set_token]= useState(null);
    const [selectItem,set_selectItem]= useState({});


    const {loading,data,error} = useQuery(getLogs,{context:{headers: {
              /*  authorization:  "Bearer "+props.state.Auth.token*/
            }},variables:{pass:globalTokens.state.readKey,_id:props.item._id}})


    if(loading||error){
        return <div></div>
    }



    return  <Drawer
        title="Logbuch"
        placement="right"
        closable={true}
        width={"90%"}
        onClose={props.onClose}
        visible={true}
    >
        <Table
            dataSource={data.superadmin.organisation.logs}  columns={[
            {
                title:"Zeitpunkt",
                dataIndex:"createdAt",
                key:"createdAt",
                render:v=>moment(parseInt(v)).format("lll")

            }, {
                title:"Text",
                dataIndex:"text",
                key:"text"

            },

            ]}
               />
    </Drawer>
}