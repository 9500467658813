import {Card, Dropdown, Menu, Drawer, Table, Button, Form, Input} from "antd";
import React, {useState} from "react";
import {useApolloClient, useQuery} from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {KeyContext} from "../../KeysContext";
import _ from "underscore";
import axios from "axios"
import {apiURL,webURL} from "../../config"
import InQuality from "./inQuality"
import Logs from "./logs"


export default (props)=>{
    let globalTokens = React.useContext(KeyContext);


    const [data,set_data]=useState({
        superPass:globalTokens.state.addKey
    })

    const client = useApolloClient()



    return  <Drawer
        title="Neue Organisation"
        placement="right"
        closable={true}
        width={"90%"}
        onClose={props.onClose}
        visible={true}
    >


        <Form {...{ wrapperCol: {
                xs: {
                    span: 24,
                    offset: 0,
                },
                sm: {
                    span: 16,
                    offset: 8,
                },
            }}}>
            <Form.Item label={"Name der Organisation"}>
                <Input value={data.name} onChange={(v)=>set_data({...data,name:v.currentTarget.value})}/>
            </Form.Item>

            <Form.Item label={"SalesfoceId"}>

                <Input value={data.salesforceId} onChange={(v)=>set_data({...data,salesforceId:v.currentTarget.value})}/>

            </Form.Item>
            <Form.Item label={"Kennung der Organisation"}>
                <p>Zum Beispiel thallux thx  am besten nur Zahlen und Buchstaben</p>

                <Input value={data.kennung} onChange={(v)=>set_data({...data,kennung:v.currentTarget.value})}/>

            </Form.Item>

            <Form.Item label={"Email des Admin"}>

                <Input value={data.login} onChange={(v)=>set_data({...data,login:v.currentTarget.value})}/>

            </Form.Item>

            <Form.Item label={"Password des Admin"}>

                <Input value={data.password} onChange={(v)=>set_data({...data,password:v.currentTarget.value})}/>

            </Form.Item>
            <Form.Item label={"Vorname des Admin"}>

                <Input value={data.vorname} onChange={(v)=>set_data({...data,vorname:v.currentTarget.value})}/>

            </Form.Item>
            <Form.Item label={"Nachname des Admin"}>

                <Input value={data.nachname} onChange={(v)=>set_data({...data,nachname:v.currentTarget.value})}/>

            </Form.Item>


            <Button onClick={()=>{
                if(data.password==undefined||data.password.length<8){
                    return alert("Passwort muss mindestens 8 Stellen haben")
                }

                if(data.vorname==undefined||data.vorname==""){
                    return alert("Vorname vergessen")
                }
                if(data.nachname==undefined||data.nachname==""){
                    return alert("Nachname vergessen")
                }


                axios.post(apiURL+"v1/org/neu",data).then(x=>{
                    console.log(x.data)
                    alert("Angelegt")
                    client.resetStore()
                    props.onClose()
                }).catch(x=>{
                    try{
                        alert(x.response.data.text)

                    }catch (e) {

                        alert(JSON.stringify(x))
                    }
                })
            }}>Anlegen</Button>
        </Form>

    </Drawer>
}