import * as React from "react";

let KeyContext = React.createContext();

let initialState = {
    readKey:"",
    addKey:"",
    name:"",
    loginKey:"",
};

let reducer = (state, action) => {
    switch (action.type) {
        case "reset":
            return initialState;
        case "set_readKey":
            return { ...state, readKey: action.payload};
        case "set_addKey":
            return { ...state, addKey: action.payload};
        case "set_loginKey":
            return { ...state, loginKey: action.payload};
            case "set_name":
            return { ...state, name: action.payload};

    }
};

function KeyContextProvider(props) {
    // [A]
    let [state, dispatch] = React.useReducer(reducer, initialState);
    let value = { state, dispatch };


    // [B]
    return (
        <KeyContext.Provider value={value}>{props.children}</KeyContext.Provider>
    );
}


// [C]
export { KeyContext, KeyContextProvider };