import {Button, Card, Form, Input,Upload} from "antd";
import React, {useState} from "react";
import { KeyContext } from "./../KeysContext";


export default (props)=>{

    let globalTokens = React.useContext(KeyContext);


    const [readKey,set_readKey] = useState("");
    const [addKey,set_addKey] = useState("");
    const [loginKey,set_loginKey] = useState("");


    return <Card>



        <Form>
            <Form.Item label={"LeseToken"}>

                <Input value={readKey} onChange={(v)=>set_readKey(v.currentTarget.value)}/>
            </Form.Item>
            <Form.Item label={"AddToken"}>

                <Input value={addKey} onChange={(v)=>set_addKey(v.currentTarget.value)}/>
            </Form.Item>

            <Form.Item label={"LoginKey"}>

                <Input value={loginKey} onChange={(v)=>set_loginKey(v.currentTarget.value)}/>
            </Form.Item>



            <Button

                onClick={()=>{
                    if(readKey!=""){
                        globalTokens.dispatch({type:"set_readKey",payload:readKey})
                        globalTokens.dispatch({type:"set_addKey",payload:addKey})
                        globalTokens.dispatch({type:"set_loginKey",payload:loginKey})
                    }

                }}
            >

                Login
            </Button>
        </Form>

    </Card>
}