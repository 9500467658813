import {Card, Dropdown,Menu, Drawer, Table, Button} from "antd";
import React, {useState} from "react";
import { useQuery } from '@apollo/react-hooks';
import { gql } from 'apollo-boost';
import {KeyContext} from "../../KeysContext";
import _ from "underscore";
import axios from "axios"
import {apiURL,webURL} from "../../config"
import InQuality from "./inQuality"
import Logs from "./logs"
import NeueOrganisation from "./neueOrganisation"
const getAllOrganisations = gql(`
query getAllOrganisations($pass: String) {
  superadmin(pass: $pass) {
    organisations {
      name
      salesforceId
      _id
    }
    
    einrichtungs {
        _id
        name
        iknummer
        
        kapazitaet
      organisationId
      
        
      }
  }
}

`)



export default (props)=>{
    let globalTokens = React.useContext(KeyContext);

    const [token,set_token]= useState(null);
    const [selectItem,set_selectItem]= useState({});
    const [showSwitch,set_showSwitch]= useState(null);
    const [showNeuDialog,set_showNeuDialog]= useState(false);


    let showDrawers = []

    switch (showSwitch) {
        case "logs":
            showDrawers = <Logs onClose={()=>set_showSwitch(null)} item={selectItem}/>
            break;
    }

    const {loading,data,error} = useQuery(getAllOrganisations,{context:{headers: {
              /*  authorization:  "Bearer "+props.state.Auth.token*/
            }},variables:{pass:globalTokens.state.readKey}})


    if(loading||error){
        return <div></div>
    }



    return  <Drawer
        title="Organisations"
        placement="right"
        closable={true}
        width={"90%"}
        onClose={props.onClose}
        visible={true}
    >

        {showDrawers}

        {token!=null&&<InQuality token={token} title={selectItem.name} onClose={()=>{set_token(null)}}></InQuality>}

        {showNeuDialog&&<NeueOrganisation onClose={()=>set_showNeuDialog(false)} data={data}/>}

        {globalTokens.state.addKey&&<Button onClick={()=>set_showNeuDialog(true)}>Neue Org</Button>}
        <Table
            expandedRowRender={(record, index, indent, expanded)=>{
            return <div><h3>Einrichtungen</h3>,<Table dataSource={record.einrichtungs} columns={[
                {key:"name",
                dataIndex:"name",
                title:"Name"},

                {key:"kapazitaet",
                    dataIndex:"kapazitaet",
                    title:"Kapazität"}
                    ,  {key:"iknummer",
                    dataIndex:"iknummer",
                    title:"IK-Nummer"}
            ]} /></div>
        }}

            dataSource={_.map(data.superadmin.organisations,item=>{

                item.einrichtungs = _.filter(data.superadmin.einrichtungs,subitem => subitem.organisationId === item._id)

                return item
            })} columns={[
            {
                title:"Name",
                dataIndex:"name",
                key:"name"

            },
            {key:"salesforceId",
                dataIndex:"salesforceId",
                title:"salesforceId",render:v=><a target={"_blank"} href={"https://eu26.lightning.force.com/lightning/r/Account/"+v+"/view"}>{v}</a>},{
            title:"Kapazität",
                key:"kap",
                render:(v,item)=>{
                    let sum  = 0;
                    sum = _.reduce(item.einrichtungs,(total,einrichtung)=>{
                        return total +parseInt(einrichtung.kapazitaet)
                    },0)
                    return sum

                }
            },{
            title:"Einrichtungen",
                key:"ein",
                render:(v,item)=>{
                    return item.einrichtungs.length

                }
            },{
            title:"Options",
                key:"op",
                render:(v,item)=>{

                    return <Dropdown overlay={<Menu>

                        <Menu.Item>
                            <a onClick={()=>{
                                set_selectItem(item)
                                set_showSwitch("logs")
                            }}> Logbuch</a>
                        </Menu.Item>

                    </Menu>}>
                    <Button>Optionen</Button>
                </Dropdown>

                }
            },{
            title:"Login",
                key:"login",
                render:(v,item)=>{
                if(globalTokens.state.loginKey!=""){
                    return <Button onClick={()=>{
                        axios.post(apiURL+"v1/auth/thalluxLogin",{
                            organisationId:item._id,
                            password:globalTokens.state.loginKey,
                            userId:globalTokens.state.name
                        }).then(x=>{
                            alert("wird geöffnet")

                            set_token(x.data.token)
                            set_selectItem(item)
                        })
                    }}>Login</Button>
                }else{
                    return <div></div>
                }

                }
            }
        ]} />;
    </Drawer>
}