import React from 'react';
import logo from './logo.svg';
import "antd/dist/antd.css";

import './App.css';
import ApolloClient from 'apollo-boost';
import { ApolloProvider } from '@apollo/react-hooks';
import Main from "./components/main"
import {KeyContextProvider} from "./KeysContext"
import {graphqlURL} from "./config"
const client = new ApolloClient({
  uri: graphqlURL
});

function App() {

  

  return (
      <KeyContextProvider>
        <ApolloProvider client={client}>
          <div className="App">
            <Main/>
          </div>
        </ApolloProvider>
      </KeyContextProvider>

  );
}

export default App;
