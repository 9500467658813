import {Button, Card, Input} from "antd";
import React, {useState} from "react";
import {KeyContext} from "../KeysContext";
import Organisations from "./drawer/organisations"
export default (props)=>{
    let globalTokens = React.useContext(KeyContext);

    const [showOrganisations,set_showOrganisations] = useState(false)

    return <div>


        {showOrganisations&&<Organisations onClose={()=>{set_showOrganisations(false)}}/>}

<Button onClick={()=>{set_showOrganisations(true)}}>Organisations</Button>

    </div>
}