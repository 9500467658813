import React, {useEffect, useState} from "react"
import {Drawer} from "antd";
import {webURL} from "../../config";
import jwt from "jsonwebtoken"

import Countdown from 'react-countdown';

export default (props)=>{


    const [exp,set_exp] = useState(null)
    useEffect(()=>{
        set_exp(jwt.decode(props.token).exp)
    },[])




    return  <Drawer
        title={<span>Login bei: {props.title}   |  Token gültig <Countdown onComplete={()=>{
            alert("Token Abgelaufen")
            props.onClose()
        }} date={new Date((exp)*1000)} /> | <a onClick={()=>{
            window.open(webURL+"loginViaToken/"+props.token, "_blank")

        }}>Öffnen in neuem Tab</a> </span>}
        placement="right"
        closable={true}
        width={"100%"}
        onClose={props.onClose}
        visible={true}
        bodyStyle={{  display:"flex",  height: "calc(100vh - 60px)"}}
    >


        <div  style={{flex:1,backgroundColor:"red"}}>
        <iframe src={webURL+"loginViaToken/"+props.token} width={"100%"} height={"100%"}></iframe>
        </div>
    </Drawer>
}